import * as React from "react"
import './index.css'
import Layout from "../components/layout"
import SEO from "../components/seo"

import logo from '../images/big-logo.svg'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className='page-not-found-container'>
      <img src={logo} alt='Kevin Rich is an accountant in Pasdena Maryland' />
      <h1>404: Not Found</h1>
      <p>Ooops.. You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
